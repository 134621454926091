import { navigate } from "gatsby";
import React from "react";

import LogoIcon from "../../images/logos/main.svg";
import LogoCompact from "../../images/logos/white-crown.svg";
import LogoIconWhite from "../../images/logos/white.svg";
import styles from "./css/logo.module.scss";

const Logo = ({ compact, color }) => {
  if (compact)
    return (
      <img
        alt="Logo"
        onClick={() => navigate("/")}
        height={24}
        src={LogoCompact}
        style={{ marginRight: 20 }}
      />
    );
  return (
    <div onClick={() => navigate("/")} className={styles.logo}>
      <img alt="Logo" src={color !== "white" ? LogoIcon : LogoIconWhite} />
    </div>
  );
};

export default Logo;
