import React from "react";

import { useScript } from "../utils/hooks";

const HubspotScript = () => {
  useScript("//js.hs-scripts.com/7035523.js");
  return null;
};

const Hubspot = () => {
  return process.env.NODE_ENV === "production" ? <HubspotScript /> : null;
};

export default Hubspot;
