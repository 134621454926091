import { LoadingOutlined } from "@ant-design/icons";
import Spin from "antd/lib/spin";
import React from "react";

import Crown from "../images/logos/crown-white.svg";

const Loading = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        left: 0,
        top: 0,
        background: "#f2bb55",
        position: "fixed",
        zIndex: 100,
        // animation:
        //   "@keyframes { from { opacity: 0 } to { opacity: 1 } } 0.3s forwards",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          background: `url(${Crown})`,
          backgroundSize: "cover",
          width: 49,
          height: 58,
          marginBottom: 30,
        }}
      />
      <Spin
        style={{ display: "block" }}
        indicator={
          <LoadingOutlined
            style={{ fontSize: 24, width: 49, color: "white" }}
            spin
          />
        }
      />
    </div>
  );
};

export default Loading;
