import "typeface-roboto";

import "./src/global.scss";
import "./src/services/amplify";
import "./src/services/stripe";

import { ApolloProvider } from "@apollo/react-hooks";
import React, { Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import i18n from "./i18n";
import Loading from "./src/components/Loading";
import createStore from "./src/state";
import client from "./src/utils/client";

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const { store, persistor } = createStore();

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Suspense fallback={<Loading />}>{element}</Suspense>
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </I18nextProvider>
    </>
  );
};
