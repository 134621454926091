import { registerLocale } from "i18n-iso-countries";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import { SUPPORTED_LOCALES } from "./src/state/app";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    react: {
      wait: true,
    },
    keySeparator: "-",
  });

i18n.on("languageChanged", (language) => {
  const lang = language.length === 2 ? language : language.substring(0, 2);
  registerLocale(require(`i18n-iso-countries/langs/${"en"}.json`));

  if (!SUPPORTED_LOCALES.includes(language)) {
    i18n.changeLanguage("en");
  }
});

export default i18n;
