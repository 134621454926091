import message from "antd/lib/message";
import { findIndex, groupBy } from "lodash";
import { createSelector, createSlice } from "redux-starter-kit";

import { selectCurrentExchangeRates, selectPreferredCurrency } from "./app";

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addToCart(state, action) {
      const index = findIndex(state, { skuId: action.payload.skuId });
      if (index === -1) {
        state.push(action.payload);
        message.success("Item Added to Cart!");
      } else {
        state[index].quantity += action.payload.quantity;
        message.success("Item Updated");
      }
    },
    removeFromCart(state, action) {
      const index = findIndex(state, { skuId: action.payload.skuId });
      if (index !== -1) {
        state.splice(index, 1);
      }
    },
    resetCart() {
      return [];
    },
  },
});

const { actions, reducer } = cartSlice;

// Effects
export const { addToCart, removeFromCart, resetCart } = actions;

// Selectors
export const selectItems = (state) => state.cart;
export const selectItemsByPlatform = createSelector(selectItems, (items) =>
  groupBy(items, "platform")
);
export const selectSubtotal = createSelector(
  [selectItems, selectCurrentExchangeRates],
  (items, rates) =>
    items.reduce(
      (acc, item) =>
        (acc += (item.price / rates[item.currency]) * item.quantity),
      0
    )
);

const selectTax = createSelector(
  selectSubtotal,
  (subtotal, taxPercent = 0) => subtotal * (taxPercent / 100)
);

export const selectTotal = createSelector(
  [selectSubtotal, selectTax, selectPreferredCurrency],
  (subtotal, tax, currency) => ({
    subtotal,
    total: subtotal + tax,
    currency,
  })
);

export default reducer;
