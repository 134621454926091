import {
  ContainerFilled,
  GlobalOutlined,
  HeartTwoTone,
  MenuOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UserOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import Modal from "antd/lib/modal";
import { navigate } from "gatsby";
import React from "react";

import CartIcon from "../../images/icons/cart.svg";
import ProfileIcon from "../../images/icons/profile.svg";
import SavedIcon from "../../images/icons/saved.svg";
import ShippingIcon from "../../images/icons/shipping.svg";
import SelectLocale from "../SelectLocale";
import Markdown from "markdown-to-jsx";

import ModalComponent from "../ModalComponent";

import { aboutUsContent } from "../../markdown/aboutUs";
import { generalInformationContent } from "../../markdown/generalInformation";
import { faqContent } from "../../markdown/faq";
import deliveryContent from "../../images/delivery-content.png";
import aboutUs from "../../images/about-us-content.png";

export const iconLinks = [
  {
    alt: "Profile Icon",
    toolTip: "nav.profile",
    to: "/app/profile",
    src: ProfileIcon,
  },
  {
    alt: "Saved Icon",
    toolTip: "nav.saved",
    to: "/app/saved",
    src: SavedIcon,
    badgeState: `saved`,
  },
  {
    alt: "Cart Icon",
    toolTip: "nav.cart",
    to: "/app/cart",
    src: CartIcon,
    badgeState: `cart`,
  },
  {
    alt: "Shipping Icon",
    toolTip: "nav.orders",
    to: "/app/orders",
    src: ShippingIcon,
    badgeState: `orders.orders`,
  },
];

export const navLinks = [
  { text: "nav.shopMucho", to: "/app/search" },
  // { text: "Wet Market", to: "/wet-market" },
  // { text: "Ship For Me", to: "/app/shipping/ship-for-me" },
  // { text: "nav.shippingService", to: "/app/shipping" },
];

export const footerIconsMobile = [
  {
    text: "nav.shop",
    to: "/",
    renderIcon: (props) => <SearchOutlined {...props} />,
  },
  {
    text: "nav.saved",
    to: "/app/saved",
    badgeState: `saved`,
    renderIcon: (props) => <HeartTwoTone twoToneColor="#EC4F26" {...props} />,
  },
  {
    text: "nav.orders",
    to: "/app/orders",
    badgeState: `orders.orders`,
    renderIcon: (props) => <ContainerFilled {...props} />,
  },
  {
    text: "nav.settings",
    to: "settings",
    renderIcon: (props) => <MenuOutlined {...props} />,
  },
];

export const navLinksMobile = [
  {
    key: "language",
    text: "nav.language",
    renderIcon: () => <GlobalOutlined />,
    onClick: () => {
      Modal.info({
        centered: true,
        maskClosable: true,
        title: "Select Language",
        content: <SelectLocale />,
      });
    },
  },
  {
    key: "currency",
    text: "nav.currency",
    renderIcon: () => <DollarOutlined />,
  },
  {
    key: "profile",
    text: "nav.profile",
    renderIcon: () => <UserOutlined />,
    onClick: () => navigate(),
    children: [
      // {
      //   key: "user",
      //   text: "nav.userInfo",
      //   onClick: () => navigate("/app/profile"),
      // },
      {
        key: "addresses",
        text: "nav.addresses",
        onClick: () => navigate("/app/addressmanager"),
      },
      // {
      //   key: "wallet",
      //   text: "nav.wallet",
      //   onClick: () => navigate("/app/walletmanager"),
      // },
      {
        key: "logout",
        text: "nav.logout",
        onClick: () => navigate("/app/login"),
      },
    ],
  },
  {
    key: "help",
    text: "nav.help",
    renderIcon: () => <QuestionCircleOutlined />,
    children: [
      {
        key: "intro",
        text: "nav.intro",
        onClick: () =>
          Modal.info({
            maskClosable: true,
            icon: null,
            okText: "Close",
            width: "80%",
            content: (
              <ModalComponent>
                <img
                  alt="About Us"
                  src={aboutUs}
                  style={{ maxHeight: "100vh", maxWidth: "100%" }}
                />
              </ModalComponent>
            ),
          }),
      },
      {
        key: "delivery",
        text: "nav.delivery",
        onClick: () =>
          Modal.info({
            maskClosable: true,
            icon: null,
            okText: "Close",
            width: "80%",
            content: (
              <ModalComponent>
                <img
                  alt="About Us"
                  src={deliveryContent}
                  style={{ maxHeight: "100vh", maxWidth: "100%" }}
                />
              </ModalComponent>
            ),
          }),
      },
      {
        key: "generalInformation",
        text: "nav.generalInformation",
        onClick: () =>
          Modal.info({
            maskClosable: true,
            icon: null,
            okText: "Close",
            width: "80%",
            content: (
              <ModalComponent>
                <Markdown>{generalInformationContent}</Markdown>
              </ModalComponent>
            ),
          }),
      },
      {
        key: "aboutUs",
        text: "nav.aboutUs",
        onClick: () =>
          Modal.info({
            maskClosable: true,
            icon: null,
            okText: "Close",
            width: "80%",
            content: (
              <ModalComponent>
                <Markdown>{aboutUsContent}</Markdown>
              </ModalComponent>
            ),
          }),
      },
      {
        key: "faq",
        text: "nav.faq",
        onClick: () =>
          Modal.info({
            maskClosable: true,
            icon: null,
            okText: "Close",
            width: "80%",
            content: (
              <ModalComponent>
                <Markdown>{faqContent}</Markdown>
              </ModalComponent>
            ),
          }),
      },
    ],
  },
];
