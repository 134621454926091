import Badge from "antd/lib/badge";
import Button from "antd/lib/button";
import Space from "antd/lib/space";
import Tabs from "antd/lib/tabs";
import { navigate } from "gatsby";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import styles from "./css/footerMobile.module.scss";
import { footerIconsMobile } from "./links";

const { TabPane } = Tabs;

const FooterMobile = ({ isSiderCollapsed, setSiderCollapsed }) => {
  const { t } = useTranslation();
  const selectState = useSelector((state) => state);

  const handleClick = (to) => {
    if (to === "settings") setSiderCollapsed(!isSiderCollapsed);
    else {
      setSiderCollapsed(true);
      navigate(to);
    }
  };
  return (
    <Tabs
      onTabClick={handleClick}
      tabPosition="bottom"
      type="card"
      animated={false}
      className={styles.tabs}
    >
      {footerIconsMobile.map((link) => {
        const { to, renderIcon, badgeState, text } = link;
        const count = get(selectState, badgeState, []).length;

        return (
          <TabPane
            key={to}
            tab={
              <Button
                type="link"
                className={styles.tab}
                style={{
                  width: `calc( 98vw / ${footerIconsMobile.length})`,
                }}
              >
                <Badge count={count} size="small">
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                    size={1}
                  >
                    {renderIcon({
                      style: { fontSize: 24, marginRight: 0, marginBottom: 0 },
                    })}
                    <span>{t(text)}</span>
                  </Space>
                </Badge>
              </Button>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default FooterMobile;
