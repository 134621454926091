import axios from "axios";

import { getToken } from "./helpers";

export const awsFunctions = axios.create({
  baseURL: process.env.GATSBY_API_URL,
});

export const orderCheckout = async (payload) => {
  const { data } = await awsFunctions.post(`/orders/checkout`, payload, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
  return data;
};

export const wetMarketCheckout = async (payload) => {
  const { data } = await awsFunctions.post(`/orders/mwm`, payload, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });
  return data;
};

export const shippingCheckout = async (payload) => {
  const { data } = await awsFunctions.post(
    `/shipping-orders/checkout`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${await getToken()}`,
      },
    }
  );
  return data;
};

//orderCheckout + shippingCheckout
export const savePaymentMethod = async (payload) =>
  awsFunctions.put(`/payment/paymentMethods/${payload.id}`, payload, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });

export const listPaymentMethods = async () =>
  awsFunctions.get(`/payment/paymentMethods`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });

export const deletePaymentMethod = async (id) =>
  awsFunctions.delete(`/payment/paymentMethods/${id}`, {
    headers: {
      Authorization: `Bearer ${await getToken()}`,
    },
  });

export const getProductDetail = async ({ platform, id }) => {
  const { data } = await awsFunctions.post(`/${platform}/getProduct`, {
    id,
  });
  return data;
};
