import React from "react";
import { connectHighlight } from "react-instantsearch-dom";

const Highlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: "_highlightResult",
    includedAttributes: ["categories"],
    attribute,
    hit,
  });
  return (
    <span className="ais-Highlight">
      {parsedHit.map((part, index) => {
        const value =
          part.value.length > 40
            ? part.value.substring(0, 40) + "... "
            : part.value;
        return part.isHighlighted ? (
          <mark className="ais-Highlight-highlighted" key={index}>
            {value}
          </mark>
        ) : (
          <span className="ais-Highlight-nonHighlighted" key={index}>
            {value}
          </span>
        );
      })}
    </span>
  );
};

export default connectHighlight(Highlight);
