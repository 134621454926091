import { Button, Col, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import sgqrApps from "../../images/sgqr-apps.png";
import { resetScroll } from "../../utils/helpers";
import styles from "./css/footer.module.scss";
import { navLinksMobile } from "./links";
import Logo from "./Logo";

const footerLinks = navLinksMobile.filter((link) => link.key === "help")[0]
  .children;

const FooterContent = () => {
  const { t } = useTranslation();
  return (
    <Row align="middle" justify="space-around" className={styles.footer}>
      <Col>
        <Space size="large">
          <Logo />
          <img style={{ height: 60 }} alt="Sgqr Apps" src={sgqrApps} />
        </Space>
      </Col>
      <Col>
        <Space size={0}>
          {footerLinks.map((footerLink) => {
            const { onClick, text, key } = footerLink;
            return (
              <Button
                type="link"
                key={key}
                onClick={() => {
                  onClick();
                  resetScroll();
                }}
              >
                {t(text)}
              </Button>
            );
          })}
        </Space>
      </Col>
    </Row>
  );
};
export default FooterContent;
