import message from "antd/lib/message";
import { findIndex } from "lodash";
import { createSlice } from "redux-starter-kit";

const addressSlice = createSlice({
  name: "addresses",
  initialState: {
    addressInfo: [],
  },

  reducers: {
    addAddress(state, action) {
      const index = findIndex(state.addressInfo, { name: action.payload.name });
      if (index === -1) {
        message.success("Address Added!");
        state.addressInfo = [...state.addressInfo, action.payload];
      } else if (action.payload.name !== state.addressInfo[index].name) {
        message.success("Address Added!");
        state.addressInfo = [...state.addressInfo, action.payload];
      } else {
        message.warn("You've already added this Address!");
        return state;
      }
    },
    editAddress(state, action) {
      const index = findIndex(state.addressInfo, { name: action.payload.name });
      if (index === -1) {
        message.warn("Nothing Edited!");
      } else if (action.payload.name === state.addressInfo[index].name) {
        message.info("Address Edited!", {
          bar: { color: "#57868f" },
        });
        state.addressInfo = Object.assign([], state.addressInfo, {
          [index]: action.payload,
        });
      } else {
        message.error("Address doesn't exist!");
      }
    },
    removeAddress(state, action) {
      //const index = findIndex(state.addressInfo, {name: action.payload.name})
      message.info("Address Removed!");
      state.addressInfo = state.addressInfo.filter(
        (todo) => todo.name !== action.payload.name
      );
    },
    setDefault(state, action) {
      const index = findIndex(state.addressInfo, { name: action.payload.name });
      const obj = state.addressInfo[index];
      if (action.payload.name === obj.name) {
        message.info("Set as Default");
        const newState = [
          action.payload,
          ...state.addressInfo.slice(0, index),
          ...state.addressInfo.slice(index + 1),
        ];
        state.addressInfo = newState;
      }
    },
  },
});
// Extract the action creators object and the reducer
const { actions, reducer } = addressSlice;

export const {
  addAddress,
  removeAddress,
  setDefault,
  editAddress,
  resetAllAddress,
} = actions;

export default reducer;
