import { findIndex } from "lodash";
import { createSlice } from "redux-starter-kit";

import * as API from "../utils/api";

const walletSlice = createSlice({
  name: "wallet",
  initialState: [],
  reducers: {
    setPaymentMethods(state, action) {
      return action.payload;
    },
    addToWallet(state, action) {
      const index = findIndex(state, { number: action.payload.number });
      if (index === -1) {
        console.log("Added first item");
        const newState = [...state, action.payload];
        return newState;
      } else if (action.payload.name !== state[index].name) {
        console.log("Added second item");
        const newState = [...state, action.payload];
        return newState;
      } else {
        console.log("You've already added this wallet!");
        alert("You've already added this Wallet!");
        return state;
      }
    },
    editWallet(state, action) {
      const index = findIndex(state, { number: action.payload.number });
      if (index === -1) {
        console.log("Nothing Edited!");
      } else if (action.payload.name === state[index].name) {
        console.log("Payment Method Edited!");
        state = Object.assign([], state, {
          [index]: action.payload,
        });
      } else {
        console.log("Error! Payment Method doesn't exist.");
      }
    },
    removeFromWallet(state, action) {
      //const index = findIndex(state, { name: action.payload.name });
      return state.filter((todo) => todo.number !== action.payload.number);
    },
    resetWallet(state, action) {
      console.log("reset");
      // return (state = [])
    },
  },
});

const { actions, reducer } = walletSlice;

// Side-effects
export const savePaymentMethod = (paymentMethod) => async (dispatch) => {
  await API.savePaymentMethod(paymentMethod);
  dispatch(listPaymentMethods());
};
export const listPaymentMethods = () => async (dispatch) => {
  const res = await API.listPaymentMethods();
  dispatch(setPaymentMethods(res.data));
};

export const deletePaymentMethod = ({ id }) => async (dispatch) => {
  await API.deletePaymentMethod(id);
  dispatch(listPaymentMethods());

  // @TODO: jono
  // If this payment method is set as default, need to update Redux as well
};

export const {
  addToWallet,
  removeFromWallet,
  editWallet,
  resetWallet,
  setPaymentMethods,
  deltePaymentMethod,
} = actions;

export default reducer;
