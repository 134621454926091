import { createSelector, createSlice } from "redux-starter-kit";

export const SUPPORTED_CURRENCIES = ["AUD", "SGD", "CNY", "USD"];
export const SUPPORTED_LOCALES = ["en", "zh-CN" /* "ms", "ta" */];

// TODO: This is hard-coded at the moment. Need to retrieve from either browser preferences or prompt user to select default currency
export const DEFAULT_LOCALE = "en";
export const DEFAULT_CURRENCY = "SGD";

const appSlice = createSlice({
  name: "app",
  initialState: {
    fx: {},
    locale: DEFAULT_LOCALE,
    currency: DEFAULT_CURRENCY,
  },
  reducers: {
    setExchangeRates(state, action) {
      state["fx"][action.payload.base] = action.payload;
      console.log("Exchange rates updated.");
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setLocale(state, action) {
      state.locale = action.payload;
    },
  },
});

const { actions, reducer } = appSlice;

// Effects
export const { setExchangeRates, setCurrency, setLocale } = actions;
export const fetchExchangeRates = () => (dispatch) => {
  Promise.all(
    SUPPORTED_CURRENCIES.map((currency) =>
      fetch(
        `https://api.exchangeratesapi.io/latest?base=${currency}`
      ).then((res) => res.json())
    )
  ).then((results) => {
    results.forEach((result) => dispatch(setExchangeRates(result)));
  });
};

// Selectors
export const selectPreferredCurrency = (state) => state.app.currency;
export const selectExchangeRates = (state) => state.app.fx;
export const selectCurrentExchangeRates = createSelector(
  [selectPreferredCurrency, selectExchangeRates],
  (currency, fx) => fx[currency]?.rates || { [DEFAULT_CURRENCY]: 1 }
);
export default reducer;
