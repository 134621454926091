export const OrderStatus = {
  CREATED: "CREATED",
  PURCHASED: "PURCHASED",
  ARRIVED_AT_WAREHOUSE: "ARRIVED_AT_WAREHOUSE",
  CONSOLIDATED: "CONSOLIDATED",
  DISPATCHED: "DISPATCHED",
  CANCELLED: "CANCELLED",
  RETURNED: "RETURNED",
};

export const ConsolidationOrderStatus = {
  CONSOLIDATED: "CREATED",
  DISPATCHED: "DISPATCHED",
  DELIVERED: "DELIVERED",
};

// TODO what's the chargeable weight threshold for big/small items in kg?
export const LARGE_ITEMS_THRESHOLD = 7000;

export const CAROUSEL_INTERVAL = 8000;
