import message from "antd/lib/message";
import { findIndex } from "lodash";
import { createSlice } from "redux-starter-kit";

const savedSlice = createSlice({
  name: "saved",
  initialState: [],
  reducers: {
    addToSaved(state, action) {
      const index = findIndex(state, { title: action.payload.title });
      if (index === -1) {
        message.success("Item Added!");
        return [...state, action.payload];
      } else if (action.payload.title !== state[index].title) {
        message.success("Item Added");
        return [...state, action.payload];
      } else {
        message.info("Item Updated");
        state[index].quantity += 1;
      }
    },
    removeFromSaved(state, action) {
      return state.filter((todo) => todo.title !== action.payload.title);
    },
  },
});
const { actions, reducer } = savedSlice;

export const { addToSaved, removeFromSaved } = actions;

export default reducer;
