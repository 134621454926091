import Select from "antd/lib/select";
import React from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const SelectLocale = () => {
  const { i18n } = useTranslation();
  const handleChange = (value) => i18n.changeLanguage(value);

  const value = i18n.language;

  return (
    <Select
      size="small"
      dropdownMatchSelectWidth={false}
      bordered={false}
      value={value}
      defaultValue="en"
      onChange={handleChange}
    >
      <Option value="en">English</Option>
      <Option value="zh-CN">简体中文</Option>
      {/* <Option value="ms">Melayu</Option>
      <Option value="ta">தமிழ்</Option> */}
    </Select>
  );
};

export default SelectLocale;
