import React from "react";
import { connectHits } from "react-instantsearch-dom";

import Hit from "./Hit";

const Hits = ({ hits, onSelect }) => {
  return (
    <div className="ais-Hits">
      <ul className="ais-Hits-list">
        {hits.map((hit) => (
          <Hit key={hit.objectID} hit={hit} onSelect={onSelect} />
        ))}
      </ul>
    </div>
  );
};

export default connectHits(Hits);
