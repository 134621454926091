import Affix from "antd/lib/affix";
import React from "react";

import styles from "./css/headerMobile.module.scss";

const HeaderMobile = ({ title, actions }) => {
  return (
    <Affix offsetTop={0}>
      <header className={styles.header}>{title}</header>
      {actions && (
        <div className={styles.actionsWrapper}>
          <div className={styles.actions}>{actions}</div>
        </div>
      )}
    </Affix>
  );
};

export default HeaderMobile;
