module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/packages/mmucho-web/src/components/Layout/index.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://947b28f7a5b34ad2b08a0716a38087df@o395576.ingest.sentry.io/5247661","enabled":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mmucho Web Market","short_name":"Mmucho","start_url":"/","background_color":"#fcfcfc","theme_color":"#f2bb55","display":"standalone","icon":"src/images/logos/crown.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"cb57cf4d00f44bc5be16481111f083f6"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"maximumFileSizeToCacheInBytes":5000000}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
