import "./css/antd.less";
import "./css/antd.scss";
import "./css/algolia.css";

import { useLazyQuery } from "@apollo/react-hooks";
import algoliasearch from "algoliasearch/lite";
import { Badge, Button, Col, Layout as Container, Menu, Row } from "antd";
import { gql } from "apollo-boost";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstantSearch } from "react-instantsearch-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import config from "../../config";
import CartEmpty from "../../images/icons/cart-empty.svg";
import CartFull from "../../images/icons/cart-full.svg";
import { fetchExchangeRates, setLocale } from "../../state/app";
import { getLoggedInUser } from "../../state/user";
import { listPaymentMethods } from "../../state/wallet";
import { resetScroll } from "../../utils/helpers";
import Hubspot from "../Hubspot";
import SearchBar from "../Search/SearchBar";
import { useURLSync } from "../URLSync";
import styles from "./css/layout.module.scss";
import FooterContent from "./FooterContent";
import FooterMobile from "./FooterMobile";
import HeaderContent from "./HeaderContent";
import HeaderMobile from "./HeaderMobile";
import { navLinksMobile } from "./links";
import Logo from "./Logo";
import SelectCurrency from "../SelectCurrency";
import Modal from "antd/lib/modal";

const { Sider } = Container;
const { SubMenu } = Menu;

const searchClient = algoliasearch(config.algolia.appId, config.algolia.apiKey);

const MmuchoLayout = ({ children, ...pageProps }) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 1224 });
  const isTiny = useMediaQuery({ maxWidth: 460 });

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);

  const { createURL, searchState, onSearchStateChange } = useURLSync(pageProps);

  const [getCustomer] = useLazyQuery(GET_USER, {
    onCompleted() {
      dispatch(listPaymentMethods());
    },
  });

  const [isCurrencyVisible, setCurrencyVisible] = useState(false);

  useEffect(() => {
    dispatch(getLoggedInUser());
    dispatch(fetchExchangeRates());
  }, [dispatch]);

  useEffect(() => {
    if (user.isUserLoggedIn) {
      getCustomer();
    }
  }, [dispatch, getCustomer, user.isUserLoggedIn]);

  useEffect(() => {
    dispatch(setLocale(language));
  }, [dispatch, language]);

  const [isSiderCollapsed, setSiderCollapsed] = useState(true);

  return (
    <>
      <InstantSearch
        indexName={config.algolia.primaryIndex}
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Container className={styles.container}>
          {isDesktop && (
            <>
              <Hubspot />
              <HeaderContent user={user} searchState={searchState} />
              <Row justify="center">
                <Col md={22} lg={20} className={styles.content}>
                  {children}
                </Col>
              </Row>
              <FooterContent />
            </>
          )}
          {isMobile && (
            <>
              <Row justify="center" align="middle">
                <HeaderMobile
                  title={
                    <Row align="middle">
                      <Logo color="white" compact={isTiny} />
                      <Col flex="auto">
                        <SearchBar />
                      </Col>
                      <Button
                        type="link"
                        onClick={() => navigate("app/wet-market/checkout")}
                        style={{ padding: 0, marginLeft: 15 }}
                      >
                        <Badge count={cart.length} size="small">
                          <img
                            src={cart.length > 0 ? CartFull : CartEmpty}
                            style={{ width: 30 }}
                            alt="Shopping Basket icon"
                          />
                          {/* <p>{t("nav.cart")}</p> */}
                        </Badge>
                      </Button>
                    </Row>
                  }
                />
                <Col className={styles.contentMobile}>
                  <Sider
                    theme="light"
                    style={{
                      overflow: "auto",
                      height: "100vh",
                      position: "fixed",
                      top: 0,
                      paddingTop: 20,
                      zIndex: 2,
                      right: 0,
                    }}
                    collapsed={isSiderCollapsed}
                    collapsedWidth={0}
                  >
                    <Menu mode="inline">
                      {navLinksMobile.map((link) => {
                        const {
                          key,
                          text,
                          renderIcon,
                          onClick,
                          children,
                        } = link;
                        if (children)
                          return (
                            <SubMenu
                              key={key}
                              title={
                                <Button type="link">
                                  {renderIcon && renderIcon()}
                                  {t(text)}
                                </Button>
                              }
                            >
                              {children.map((childLink) => {
                                const {
                                  key,
                                  onClick,
                                  text,
                                  renderIcon,
                                } = childLink;
                                return (
                                  <Menu.Item key={key}>
                                    <Button
                                      type="link"
                                      onClick={() => {
                                        if (onClick) {
                                          onClick();
                                          resetScroll();
                                          setSiderCollapsed(true);
                                        }
                                      }}
                                    >
                                      {renderIcon && renderIcon()}
                                      {t(text)}
                                    </Button>
                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        else
                          return (
                            <Menu.Item key={key}>
                              <Button
                                type="link"
                                onClick={() => {
                                  if (key === "currency") {
                                    setCurrencyVisible(true);
                                  }
                                  if (onClick) {
                                    onClick();
                                    setSiderCollapsed(true);
                                  }
                                }}
                              >
                                {renderIcon && renderIcon()}
                                {t(text)}
                              </Button>
                            </Menu.Item>
                          );
                      })}
                    </Menu>
                  </Sider>
                  <Modal
                    title={t("nav.currency")}
                    visible={isCurrencyVisible}
                    onOk={() => setCurrencyVisible(false)}
                    onCancel={() => setCurrencyVisible(false)}
                    maskClosable={false}
                  >
                    <SelectCurrency />
                  </Modal>
                  {children}
                </Col>
              </Row>
              <FooterMobile
                isSiderCollapsed={isSiderCollapsed}
                setSiderCollapsed={(v) => setSiderCollapsed(v)}
              />
            </>
          )}
        </Container>
      </InstantSearch>
    </>
  );
};

export default MmuchoLayout;

export const GET_USER = gql`
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customerNo
      name
      email
      phoneNumber
      picture
      gender
      birthdate
      country
      addresses {
        city
        country
        line1
        line2
        postcode
        state
        isDefault
      }
      metadata
      createdAt
      updatedAt
      _type
    }
  }
`;
