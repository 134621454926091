import { Link } from "gatsby";
import { last } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import Highlight from "./Highlight";

const Hit = ({ hit, onSelect }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <li className="ais-Hits-item">
      <Link
        to={`/product/${hit?.slug}`}
        onClick={() => onSelect(hit.title[language])}
      >
        <Highlight attribute={`title.${language}`} hit={hit} />
        <span className="category">{last(hit.categories[language])}</span>
      </Link>
    </li>
  );
};

export default Hit;
