import { navigate } from "gatsby";
import React from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./css/searchbox.module.scss";
import SearchBox from "./SearchBox";

// TODO: Implement taobao lookup onEnter
// const getSearchDestination = (searchState) => {
//   const query = searchState.query || "";

//   const isTaobao = () => {
//     if (query.indexOf("taobao") > -1) return true;
//     else if (query.indexOf("tmall") > -1) return true;
//     else return false;
//   };

//   const platform = isTaobao() && "taobao";

//   const regex = /id=\d+/g;
//   const id = isTaobao() && regex.exec(query)[0];

//   const url = `/app/buy-for-me?${id}&platform=${platform}`;
//   const searchURL = `/app/search?query=${query}`;

//   if (isTaobao()) {
//     return url;
//   } else {
//     return searchURL;
//   }
// };

const SearchBar = () => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  const onSubmit = (query) => {
    navigate(`/app/search/?query=${query}`);
  };

  return (
    <div className={isMobile ? styles.mobile : styles.desktop}>
      <SearchBox handleSubmit={onSubmit} />
    </div>
  );
};

export default SearchBar;
