import { createSelector, createSlice } from "redux-starter-kit";

import { OrderStatus } from "../utils/constants";

const ordersSlice = createSlice({
  name: "orders",
  initialState: { orders: [], selectedRowKeys: [] },
  reducers: {
    setOrders(state, action) {
      state.orders = action.payload;
    },
    selectRow(state, action) {
      const key = action.payload;
      const index = state.selectedRowKeys.indexOf(key);
      if (index > -1) {
        state.selectedRowKeys.splice(index, 1);
      } else {
        state.selectedRowKeys.push(key);
      }
    },
    selectAll(state, action) {
      state.selectedRowKeys = state.orders.reduce((keys, order) => {
        order.lineItems.items.forEach((lineItem) => {
          keys.push(`${order.id}-${lineItem.skuId}`);
        });
        return keys;
      }, []);
    },
    deselectAll(state, action) {
      state.selectedRowKeys = [];
    },
  },
});

const { actions, reducer } = ordersSlice;

// Side-effects
export const { selectRow, selectAll, deselectAll, setOrders } = actions;
export const handleSelectAll = (shouldSelectAll) => (dispatch) => {
  dispatch(shouldSelectAll ? selectAll() : deselectAll());
};

// Selectors
export const selectOrders = (state) => state.orders.orders;
export const selectSelectedRows = (state) => state.orders.selectedRowKeys;
export const selectPickedOrders = createSelector(
  [selectOrders, selectSelectedRows],
  (orders, keys) => {
    return orders.filter((order) =>
      order.lineItems.items.some((lineItem) =>
        keys.includes(`${order.id}-${lineItem.skuId}`)
      )
    );
  }
);
export const selectPickedOrdersTotalWeight = createSelector(
  selectPickedOrders,
  (orders) => {
    return orders.reduce((acc, order) => {
      const sumOrderWeight = order.parcels.items.reduce((acc, item) => {
        if (!item) return acc;
        return (acc += item.parcel.measurement.chargeableWeight);
      }, 0);
      return (acc += sumOrderWeight);
    }, 0);
  }
);

export const selectIsConsolidationAvailable = createSelector(
  selectOrders,
  (orders) =>
    orders.some((order) => order.status === OrderStatus.ARRIVED_AT_WAREHOUSE)
);

export default reducer;
