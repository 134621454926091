import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "redux-starter-kit";

import addresses from "./addresses";
import app from "./app";
import cart from "./cart";
import orders from "./orders";
import saved from "./saved";
import user from "./user";
import wallet from "./wallet";

const reducer = combineReducers({
  app,
  cart,
  saved,
  user,
  addresses,
  wallet,
  orders,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export default function createStore(preloadedState) {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
  });
  const persistor = persistStore(store);
  return { store, persistor };
}
