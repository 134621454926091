import { Auth } from "aws-amplify";

// Conditioning logics
export const isClientPaths = (location) => location.pathname.startsWith("/app");

// Getters
export const getToken = async () => {
  const session = await Auth.currentSession().catch(console.log);
  if (session) {
    return session.getIdToken().getJwtToken();
  }
};

// Formatters
export const formatCurrency = (amount, currency, locale) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });
  return formatter.format(amount / 100);
};

export const formatOrderRecipient = (order) => {
  const { line1, country } = order?.shipping?.address;
  return order?.warehouse?.country || `${line1}, ${country}`;
};

export const formatError = (error) => {
  const { code, message } = error;
  console.log(error);
  switch (code) {
    case "InvalidParameterException":
      return "Your email isn't valid or your password isn't longer than 6 characters.";
    case "UserLambdaValidationException":
      return "Can't read password.";
    default:
      return message;
  }
};

/**
 * FIXME:(@jono.vu) Temporary solution to fix modal scroll position.
 * Refactor the way footer links are handled instead.
 */
export const resetScroll = () => {
  setTimeout(() => {
    document.getElementsByClassName("ant-modal-wrap")[0].scrollTo(0, 0);
  }, 300);
};
