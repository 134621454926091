import { Auth } from "aws-amplify";
import { navigate } from "gatsby";
import { createSlice } from "redux-starter-kit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isUserLoggedIn: null,
    userAttr: {},
  },
  reducers: {
    setUser(state, action) {
      delete state.loginChallenge;
      state.userAttr = action.payload;
      state.isUserLoggedIn = true;
    },
    setLogin(state, action) {
      state.isUserLoggedIn = action.payload || false;
    },
    setLoginChallenge(state, action) {
      state.loginChallenge = action.payload;
    },
    logout(state, action) {
      state.userAttr = {};
      state.isUserLoggedIn = false;
    },
  },
});

// Side-Effects
export const getLoggedInUser = () => async (dispatch, getState) => {
  if (getState().user.isUserLoggedIn !== null) return;

  try {
    const user = await Auth.currentAuthenticatedUser();
    dispatch(setUser(user.attributes));
  } catch (err) {
    // throws an error if there is no user logged in
    dispatch(setLogin(false));
  }
};

export const loginUser = (values, redirectTo) => async (dispatch) => {
  const user = await Auth.signIn(values);

  dispatch(setUser(user.attributes));
  navigate(redirectTo || "/");
};

export const loginUserPasswordlessly = (
  tempUser,
  challengeResponse,
  redirectTo
) => async (dispatch, getState) => {
  const user = await Auth.sendCustomChallengeAnswer(
    tempUser,
    challengeResponse
  );

  dispatch(setUser(user.attributes));
  navigate(redirectTo || "/");
};

export const logoutUser = () => async (dispatch) => {
  await Auth.signOut();
  dispatch(logout());
  navigate("/");
};

export const registerUser = (values) => async (dispatch) => {
  const payload = values;

  // TEMP: Check if username is email / phone_number
  if (values.username.startsWith("+")) {
    payload.attributes = { phone_number: values.username };
  } else {
    payload.attributes = { email: values.username };
  }

  await Auth.signUp(payload);
  await dispatch(loginUser(values));
};

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;

export const { setUser, setLogin, setLoginChallenge, logout } = actions;

export default reducer;
