import Button from "antd/lib/button";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connectSearchBox } from "react-instantsearch-dom";

import SearchIcon from "../../images/icons/search.svg";
import { useDebounce, useOnClickOutside } from "../../utils/hooks";
import Hits from "./Hits";

const SearchBox = ({ currentRefinement, refine, handleSubmit }) => {
  const { t } = useTranslation();
  const search = useRef(null);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(currentRefinement);
  const refinement = useDebounce(value, 500);

  useOnClickOutside(search, () => setShow(false));

  useEffect(() => {
    refine(refinement);
  }, [refine, refinement]);

  return (
    <div ref={search}>
      <div className="ais-SearchBox">
        <form
          className="ais-SearchBox-form"
          noValidate
          role="search"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(value);
            setShow(false);
          }}
        >
          <input
            className="ais-SearchBox-input"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder={t("nav.search.placeholder")}
            spellCheck="false"
            maxLength="512"
            type="search"
            value={value}
            onChange={(event) => setValue(event.currentTarget.value)}
            onFocus={() => setShow(true)}
          />
          <Button
            type="link"
            className="ais-SearchBox-submit"
            htmlType="submit"
          >
            {!value && (
              <img
                className="ais-SearchBox-submitIcon"
                src={SearchIcon}
                alt="search icon"
                style={{ width: 24, height: 24 }}
              />
            )}
          </Button>
          {/* <Button type="link" className="ais-SearchBox-reset" htmlType="reset">
            <CloseOutlined className="ais-SearchBox-resetIcon" />
          </Button>
          <span className="ais-SearchBox-loadingIndicator">
            <Spin className="ais-SearchBox-loadingIcon" />
          </span> */}
          <input type="submit" hidden />
        </form>
      </div>

      {show && value && (
        <Hits
          onSelect={(value) => {
            setValue(value);
            setShow(false);
          }}
        />
      )}
    </div>
  );
};

export default connectSearchBox(SearchBox);
