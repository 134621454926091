export const generalInformationContent = `## Terms and conditions

The pictures of the products shown in MWM are meant for easy recognition. As these are livestock/fresh produces, these pictures are not exact representation of the products which you will receive.

All due care will be taken to ensure correctness of descriptions, size, details and pricing (updated on weekly due to seasonal market pricing particularly so for fresh produce). Hence appreciate your kind understanding in fluctuating prices from time to time as we are replicating the wet market scenario online.

MWM reserve the right to correct any inaccuracies in the price and other details of products without prior notice, and the Customer will be bound by any adjustment to the price or description of the products notwithstanding MWM acceptance of the Customer’s order.

As much as MWM would like to fulfil and complete every order, MWM reserves the rights to decline an order for whatever reason, including but not limited to the following:

(a) Out of stock situation - The product(s) you have ordered is no longer available

(b) One or more of the Products you ordered was listed at an incorrect price due to typo pricing error

(c) Unforeseen circumstances – Act of God

Would appreciate your kind understanding that MWM will not be obliged to offer any additional compensation for consequential losses or disappointment.

MWM will also not be liable for any loss or damage whatsoever arising from refusal / cancellation of your order.

## Cancellation of order

If the order cannot be fulfilled / cancelled due to unavailable supplies, MWM will notify you through email or phone and arrange for refund to be process to you accordingly.

However, an order cannot be cancelled once the item has been processed by the market stall vendor / delivered to you.

If we suspect any fraudulent transaction by any customer or any transaction which is in non-compliance to the terms and conditions of using the website, we at our sole discretion could void such orders.

Would appreciate your kind understanding that seafood and meat like chicken, beef or pork cannot be returned once delivery is at your doorstep as they are highly perishable and individually prepared. Only vegetables and fruits can be returned if these are not fresh and good quality.

## Delivery

### Days

Monday – no deliveries as fresh market is not open

Delivery dates can only apply for 1-week window as prices are updated on weekly basis.

All orders received before 1730hrs can be delivered on the following day.

### Delivery timings available

- 10am to 2pm
- 2pm to 6pm

## Delivery charges

Delivery charges – $12 per drop off point, administrative surcharge of $10 for failed delivery and re-delivery charges re-apply.

Charges apply within inland Singapore exclusive of restricted areas / areas which required special clearance / passes. Please feel free to chat with us if you require delivery to such areas and arrangements can be made accordingly.

## Delivery procedures and conditions

With the current COVID 19 situations, contactless deliveries will be performed. Driver will call you prior to arrival and press the doorbell upon arrival. Kindly ensure you are available during the date and time of delivery.

Items will be placed at the door and picture will be taken to display where the items are placed.

Would appreciate if the items are immediately retrieved from the door as these are fresh produces from the wet market.

All our drivers will be equipped with the necessary healthcare PPE and sanitization will be carried after each delivery.

## Preparations and conditions of items

All orders are taken minimum 1 day before and prepared on the next morning for delivery. Hence, we would appreciate your kind understanding that all orders are deem as confirmed when you have checkout and payment has been made. This is to assist all the market stall holders to reserve and prepare your orders accordingly.

For items that are charged based on weight, we would appreciate your kind understanding as these are fresh products, there will be slim chance that every item can be exact. However, we endeavoured to match and fulfil to the closet weight mark as much as possible. Do note that all products are being weighed and priced before cleaning, descaling, gutting is performed.

Upon submission of order and payment from you, we will deem that you have agreed with our terms and conditions.`;
