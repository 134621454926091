// https://github.com/algolia/react-instantsearch/blob/master/examples/e-commerce/URLSync.js

import { useLocation, useNavigate } from "@reach/router";
import qs from "qs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import config from "../config";
import { isClientPaths } from "../utils/helpers";

const routeStateDefaultValues = {
  query: "",
  page: "1",
  category: "",
  price: "",
  sortBy: config.algolia.primaryIndex,
  hitsPerPage: "20",
};

// Returns a slug from the category name.
// Spaces are replaced by "+" to make
// the URL easier to read and other
// characters are encoded.
export function getCategorySlug(name) {
  return name.replace(/ > /g, "/").split(" ").join("+");
}

// Returns a name from the category slug.
// The "+" are replaced by spaces and other
// characters are decoded.
export function getCategoryName(path) {
  return path.split("+").join(" ").replace(/\//g, " > ");
}

export const searchStateToURL = (locale) => (searchState) => {
  const routeState = {
    query: searchState.query,
    page: String(searchState.page),
    category:
      searchState.hierarchicalMenu &&
      searchState.hierarchicalMenu[`hierarchicalCategories.${locale}.lv0`],
    price:
      searchState.range &&
      searchState.range.price &&
      `${searchState.range.price.min || ""}:${
        searchState.range.price.max || ""
      }`,
    sortBy: searchState.sortBy,
    hitsPerPage:
      (searchState.hitsPerPage && String(searchState.hitsPerPage)) || undefined,
  };

  const baseUrl = `/app/search`;

  // const categoryPath = searchState.hierarchicalMenu
  //   ? `${getCategorySlug(
  //       searchState.hierarchicalMenu.hierarchicalCategories?.map((category) => {
  //         console.log(category);
  //         return "";
  //       })
  //     )}/`
  //   : "";

  const categoryPath = routeState.category
    ? `${getCategorySlug(routeState.category)}/`
    : "";

  const queryParameters = {};

  if (routeState.query && routeState.query !== routeStateDefaultValues.query) {
    queryParameters.query = encodeURIComponent(searchState.query);
  }

  if (routeState.page && routeState.page !== routeStateDefaultValues.page) {
    queryParameters.page = searchState.page;
  }

  if (routeState.price && routeState.price !== routeStateDefaultValues.price) {
    queryParameters.price = routeState.price;
  }

  if (
    routeState.sortBy &&
    routeState.sortBy !== routeStateDefaultValues.sortBy
  ) {
    queryParameters.sortBy = routeState.sortBy;
  }

  if (
    routeState.hitsPerPage &&
    routeState.hitsPerPage !== routeStateDefaultValues.hitsPerPage
  ) {
    queryParameters.hitsPerPage = routeState.hitsPerPage;
  }

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  });

  return `${baseUrl}/${categoryPath}${queryString}`;
};

export const urlToSearchState = (locale) => (location) => {
  // NOTE: Gatsby encoded location.pathname by default, this is different from the example js
  const pathname = location.pathname;
  const search = location.search;

  const pathnameMatches = pathname.match(/search\/(.*?)\/?$/);
  const category = getCategoryName(
    (pathnameMatches && pathnameMatches[1]) || ""
  );

  const queryParameters = qs.parse(search.slice(1));
  const { query = "", page = 1, hitsPerPage, price, sortBy } = queryParameters;

  const searchState = { range: {} };

  if (query) {
    searchState.query = decodeURIComponent(query);
  }

  if (page) {
    searchState.page = page;
  }

  if (category) {
    searchState.hierarchicalMenu = {
      [`hierarchicalCategories.${locale}.lv0`]: category,
    };
  }

  if (price) {
    const [min, max = undefined] = price.split(":");
    searchState.range.price = {
      min: min || undefined,
      max: max || undefined,
    };
  }

  if (sortBy) {
    searchState.sortBy = sortBy;
  }

  if (hitsPerPage) {
    searchState.hitsPerPage = hitsPerPage;
  }

  return searchState;
};

export const useURLSync = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState({});

  useEffect(() => {
    if (isClientPaths(location)) {
      setSearchState(urlToSearchState(language)(location));
    }
  }, [language, location]);

  const onSearchStateChange = (updatedSearchState) => {
    // Update URL (cause searchState change as a side-effect) when the current page view is matched with the /app/search route
    const shouldUpdateURL = location.pathname.startsWith("/app/search");
    if (shouldUpdateURL) {
      navigate(searchStateToURL(language)(updatedSearchState));
    }

    // Update search state but do not update URL when the search is initiated by the user via the search bar without regards to current page
    else {
      if (
        updatedSearchState.query &&
        updatedSearchState.query !== searchState.query
      ) {
        setSearchState(updatedSearchState);
      }
    }
  };

  return {
    searchState,
    onSearchStateChange,
    createURL: searchStateToURL(language),
  };
};
