export default {
  aws: {
    aws_appsync_graphqlEndpoint: process.env.GATSBY_GRAPHQL_ENDPOINT,
    aws_appsync_region: "ap-southeast-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    ...JSON.parse(process.env.GATSBY_AWS_CONFIG || "{}"),
  },
  algolia: {
    appId: "0FTTG0FCTN",
    apiKey: "79e2e3b831e16685d117ce287a8d9643",
    primaryIndex: process.env.GATSBY_ALGOLIA_PRIMARY_INDEX,
  },
  google: {
    mapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  },
  whatsapp: {
    support: "https://wa.me/6591505222",
  },
};
