export const aboutUsContent = `# About us

We started initially as customers of wet market in Toa Payoh and due to the COVID19 we realised that many stall holders are badly affected and their business dropped drastically and food wastage went up.

With MWM, we can bridge customers who need the food supplies with the wet market holders, ensuring social distancing, minimise mingling, aligned with circuit breaker and upgrade the skill sets of each and every stall holder in one way or another.

Hence, we started this platform to assist all participating stall holders at Toa Payoh wet market to digitalise their business without having to setup their own website, without having to maintain their own website, without paying any commission fees, without paying any commission to any delivery service providers.

Centralised digitalised orders are taken and disseminated to the various stall holders and this aids them in their procurement and daily planning of their business activities. This also in turn minimise food wastage, play a part in food sustainability and protecting the environment.

### **Why do we charge delivery?**

In order to keep produce fresh, prices reasonable and competitive, all delivery charges are borne by customers instead of the stall holders. There is no minimum order for each purchase as everyone’s needs is different.

All delivery charges are being paid to the drivers and goes to the maintenance of trucks equipped to transport perishable food in proper and clean environment.

### **Be patient with us**

Please be patient with us as we are trying to assist the wet market stall holders (Aunties and Uncles) in the digitalisation of their produce and value-added services in MWM. We are trying our best to be as accurate, informative and replicate the exact wet market environment you have always enjoyed. We have enabled 2 languages (English and Mandarin) in MWM for your ease of shopping, should there be any error in translation, please inform us. We will continue to strive to include more languages to improve the shopping experience and reach out to more in the community.

**Whatsapp:** 9150 5222

**Email:** buy@mmucho.com

Pay with SGQR code

Or pay to UEN 201935705H

Kindly remember to indicate the MMucho order no. when you are making payment.`;
