import Badge from "antd/lib/badge";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import PageHeader from "antd/lib/page-header";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Tabs from "antd/lib/tabs";
import Tooltip from "antd/lib/tooltip";
import Typography from "antd/lib/typography";
import { Link, navigate } from "gatsby";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { logoutUser } from "../../state/user";
// import ShopMucho from "../ShopMucho";
import SearchBar from "../Search/SearchBar";
import SelectCurrency from "../SelectCurrency";
import SelectLocale from "../SelectLocale";
import styles from "./css/header.module.scss";
import { iconLinks, navLinks } from "./links.js";
import Logo from "./Logo";

const { TabPane } = Tabs;
const { Text } = Typography;

const IconLinks = () => {
  const { t } = useTranslation();
  const selectState = useSelector((state) => state);
  return (
    <Space size={30}>
      {iconLinks.map((iconLink) => {
        const { alt, toolTip, to, src, badgeState } = iconLink;
        const count = _.get(selectState, badgeState, []).length;
        return (
          <Tooltip title={t(toolTip)} placement="bottom" key={alt}>
            <Badge count={count} size="small">
              <Link to={to}>
                <img width={35} src={src} alt={alt} />
              </Link>
            </Badge>
          </Tooltip>
        );
      })}
    </Space>
  );
};

const NavLinks = () => {
  const { t } = useTranslation();
  return (
    <Tabs onChange={(to) => navigate(to)}>
      {navLinks.map((navLink) => {
        const { text, to } = navLink;
        return (
          <TabPane tab={<Button type="link">{t(text)}</Button>} key={to} />
        );
      })}
    </Tabs>
  );
};

const HeaderContent = ({ user, searchState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const renderExtra = () => {
    return (
      <Row justify="end" align="middle" className={styles.topBar}>
        <Space>
          {user.isUserLoggedIn ? (
            <Space>
              <Text>{t("nav.welcome")}</Text>
              <Button
                size="small"
                type="ghost"
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                {t("nav.logout")}
              </Button>
            </Space>
          ) : (
            <Link to="/app/login">Login or Register</Link>
          )}
          <SelectLocale />
          <SelectCurrency />
        </Space>
      </Row>
    );
  };
  return (
    <div>
      {renderExtra()}
      <PageHeader
        // footer={
        //   <Row justify="center">
        //     <NavLinks />
        //   </Row>
        // }
        className={styles.header}
      >
        <Row align="middle">
          <Logo color="white" />
          <Col flex="auto" style={{ padding: "0 40px" }}>
            <SearchBar />
          </Col>
          <IconLinks />
        </Row>
      </PageHeader>
    </div>
  );
};
export default HeaderContent;
