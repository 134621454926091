import { Select } from "antd";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";

import { SUPPORTED_CURRENCIES, setCurrency } from "../state/app";

const DICT = {
  CNY: "CN",
  AUD: "AU",
  SGD: "SG",
  USD: "US",
};

const { Option } = Select;

const SelectCurrency = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.app.currency);

  const handleChange = (value) => {
    dispatch(setCurrency(value));
  };

  return (
    <Select
      dropdownMatchSelectWidth={false}
      bordered={false}
      onChange={handleChange}
      value={currency}
    >
      {SUPPORTED_CURRENCIES.map((currency) => {
        return (
          <Option key={currency} value={currency}>
            <ReactCountryFlag countryCode={DICT[currency]} svg /> {currency}
          </Option>
        );
      })}
    </Select>
  );
};

export default SelectCurrency;
