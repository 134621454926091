export const faqContent = `# **FAQ**

*How do I place an order?*

1. Simply create an account @ www.mmucho.com
2. Search for your product on the search bar located near the top and add it to cart.
3. You can also create your favourite list.
4. “Checkout” when you are done with your shopping.
5. Key in the required information and order will be delivered in accordance to your preferred time slot.

*Is there any minimum order?*

There is no minimum order and one can order any amount to their own preference.

*Is there any delivery charge?*

Delivery charges – $12 per drop off point, administrative surcharge of $10 for failed delivery and re-delivery charges re-apply.

Charges apply within inland Singapore exclusive of restricted areas / areas which required special clearance / passes. Please feel free to chat with us if you require delivery to such areas and arrangements can be made accordingly.

*How do I know if my order is confirmed?*

After successfully checking out, you will be directed to an order confirmation page. Also, an order confirmation email will be sent to you.

*What if I want to make amendments to my order?*

A new order has to be made and the wrong order will be cancelled. Please inform us of the wrong order via Whatsapp immediately at 9150 5222. You can also email us at [buy@mmucho.com](mailto:buy@mmucho.com).

We will try our best to accommodate however if the order has been processed, we are apologetic that we are not able to reverse the order accordingly.

*What if I want to make a bulk order?*

If you would like to make a bulk order, please drop a Whatsapp message at 9150 5222 or alternatively you can email us at [buy@mmucho.com](mailto:buy@mmucho.com) and we will be glad to assist accordingly.

*Will I receive the products exactly the way it is shown on the picture of the product?*

As the produce are fresh and perishable, the product you receive may not look exact as what has been published on the website. Nothing natural is identical. However, be assured that the quality and product type is what you want.

*How do I know what are the available time slots?*

The available time slots are available for selection during the Checkout.

*What are the available payment methods?*

We accept payment from these digibank APPs:

![https://s3-us-west-2.amazonaws.com/secure.notion-static.com/bbc17c66-80c0-48df-a458-8b3068ff57f0/Untitled.png](https://s3-us-west-2.amazonaws.com/secure.notion-static.com/bbc17c66-80c0-48df-a458-8b3068ff57f0/Untitled.png)

Just scan our SGQR code.

Or pay to **UEN 201935705H**

Kindly remember to indicate the MMucho order no. when you are making payment.

*Can I pay by cash or cheque?*

Apologies that we cannot receive payment through these modes at the moment.

*How secure are my payment details?*

Payments are activated and received through digibank APPs. We are arranging for more modes of payment gateways, please be patient with us while we get these up and running securely for you.

*Can I choose a specific timing for the delivery?*

There are delivery windows at the moment:

- 10am to 2pm
- 2pm to 6pm

We are working right now to open up more delivery window and appreciate your kind understanding in this matter.

*If I place an order today, when will it be delivered?*

Delivery can be made on the next day from the date of order made with the exception of Mondays as wet markets are not operating.

*Where are the places you deliver to?*

We can deliver within inland Singapore exclusive of restricted areas / areas which required special clearance / passes. Please feel free to chat with us if you require delivery to such areas and arrangements can be made accordingly. We will be most glad to assist.

*What if I want to change the delivery address or time slot after making the order?*

Whatsapp us immediately at 9150 5222 and indicate your MMucho order no., and we will try our best to fulfil your request if order has not been processed. We really appreciate your patience and understanding in this matter if diversion cannot be made in time.

*What if I want my orders to be left at my doorstep or with my residence’s security guards?*

As the produce are fresh and perishable from wet markets, deliveries will not be left with the security guard.

See Delivery page for more information.

*What if no one is at home during the delivery?*

If there is failed attempt / failed delivery, re-delivery will have to be re-arranged accordingly. Administrative surcharge of $10 for failed delivery and re-delivery charges will apply accordingly.

See Delivery page for more information.

*How are my items packed and delivered?*

Your ordered items will be placed inside an insulated box equipped with ice pack to maintain the chill temperature.

*Should I keep my order in proper storage after receiving it?*

For fresh produce, it is highly recommended that immediate refrigeration is done in order to maintain the freshness.

*How do I give my feedback regarding my order?*

We need your feedback in order to improve our service and your shopping experience.

Do email us at [buy@mmucho.com](mailto:buy@mmucho.com)!

*What happens if there are missing products or products that you are unsatisfied with?*

Do email us at [buy@mmucho.com](mailto:buy@mmucho.com) so that we can proceed with the recovery actions. For missing products, we can offer refund of the missing item or arrange a redelivery for it.

*What is Mmucho© standard and guarantee?*

All our produces originated from the wet market vendors. Our Mmucho merchants have been selected and appointed through many good reviews and their quality of products.`;
