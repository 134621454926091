import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import config from "../config";
import { getToken } from "./helpers";

const { aws } = config;

const url = aws.aws_appsync_graphqlEndpoint;
const region = aws.aws_appsync_region;
const auth = {
  type: aws.aws_appsync_authenticationType,
  jwtToken: async () => await getToken(),
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }),
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
